export const processSteps = [
  {
    id: 1,
    title: "Get Product Reviewed and Approved",
    description: "Obtain necessary approvals for the product",
    status: "completed",
    icon: "✓",
    color: "bg-green-500",
    mapPosition: {
      x: 150,
      y: 140,
      width: 330,
      height: 120,
    },
    details: {
      description:
        "Product has been reviewed and approved by regulatory authorities.",
      completedItems: [
        "Product documentation submitted",
        "Safety standards verified",
        "Regulatory compliance confirmed",
      ],
      nextSteps: [],
    },
  },
  {
    id: 2,
    title: "Review Market Report",
    description: "Analyze market demand, regulations, and pricing",
    status: "completed",
    icon: "📊",
    color: "bg-green-500",
    mapPosition: {
      x: 480,
      y: 280,
      width: 340,
      height: 120,
    },
    details: {
      description:
        "Market analysis completed with positive indicators for product success.",
      completedItems: [
        "Market demand assessed",
        "Competitor analysis done",
        "Pricing strategy defined",
      ],
      nextSteps: [],
    },
  },
  {
    id: 3,
    title: "Localize Creatives and Landing Page",
    description: "Adapt marketing materials for local audiences",
    status: "completed",
    icon: "🌐",
    color: "bg-green-500",
    mapPosition: {
      x: 150,
      y: 420,
      width: 330,
      height: 120,
    },
    details: {
      description:
        "Marketing materials have been localized for target markets.",
      completedItems: [
        "Landing page translated",
        "Creative assets adapted",
        "Cultural considerations implemented",
      ],
      nextSteps: [],
    },
  },
  {
    id: 4,
    title: "Start Small Marketing Campaign",
    description: "Launch initial marketing efforts to generate sales",
    status: "current",
    icon: "🚀",
    color: "bg-orange-500",
    mapPosition: {
      x: 480,
      y: 560,
      width: 340,
      height: 120,
    },
    details: {
      description: "Ready to begin initial marketing campaign.",
      completedItems: [],
      nextSteps: [
        "Set up marketing campaigns",
        "Use AMNA localized landing page or your own",
        "Only capture name and phone number on checkout",
        "Remember your customers like post pay and cash on delivery",
      ],
    },
  },
  {
    id: 5,
    title: "Send Sales Data to AMNA",
    description: "Report sales data and initiate restock logistics",
    status: "pending",
    icon: "📊",
    color: "bg-gray-400",
    mapPosition: {
      x: 150,
      y: 700,
      width: 330,
      height: 120,
    },
    details: {
      description: "Waiting to collect and report initial sales data.",
      completedItems: [],
      nextSteps: [
        "Set up sales tracking",
        "Configure reporting system",
        "Establish restock triggers",
      ],
    },
  },
  {
    id: 6,
    title: "Access Logistics System",
    description: "Utilize logistics tools to track sales and profits",
    status: "pending",
    icon: "🔄",
    color: "bg-gray-400",
    mapPosition: {
      x: 480,
      y: 840,
      width: 340,
      height: 120,
    },
    details: {
      description: "Logistics system access pending sales initiation.",
      completedItems: [],
      nextSteps: [
        "Get system credentials",
        "Complete system training",
        "Set up tracking preferences",
      ],
    },
  },
  {
    id: 7,
    title: "Check Balance",
    description: "Verify financial status and sales proceeds",
    status: "pending",
    icon: "💰",
    color: "bg-gray-400",
    mapPosition: {
      x: 150,
      y: 980,
      width: 330,
      height: 120,
    },
    details: {
      description: "Financial verification pending sales completion.",
      completedItems: [],
      nextSteps: [
        "Set up financial tracking",
        "Configure payment notifications",
        "Prepare reporting templates",
      ],
    },
  },
  {
    id: 8,
    title: "Transfer Funds Using AmnaPay",
    description: "Complete financial transactions to transfer funds",
    status: "pending",
    icon: "💳",
    color: "bg-gray-400",
    mapPosition: {
      x: 480,
      y: 1120,
      width: 340,
      height: 120,
    },
    details: {
      description: "Fund transfer system setup pending balance verification.",
      completedItems: [],
      nextSteps: [
        "Complete AmnaPay registration",
        "Set up payment methods",
        "Configure auto-transfer rules",
      ],
    },
  },
];
