import {
  HelpCircleIcon,
  ArrowLeftIcon,
  PlusIcon,
  ArrowUpRightIcon,
  UserIcon,
  RefreshCcwIcon,
  SendIcon,
} from "lucide-react";
import React from "react";
export default function WizeCard() {
  return (
    <div className="w-full max-w-[888px] mx-auto flex flex-col gap-4 text-[#454745] text-sm leading-[21.7px] font-sans p-4">
      <div className="mt-4">
        <div>
          <div className="text-sm leading-[21.7px] -tracking-[0.084px] font-semibold">
            Total balance
          </div>
          <div className="flex items-center gap-3">
            <h3 className="text-[#0e0f0c] leading-[27.5px] -tracking-[0.396px] text-[22px] font-semibold m-0">
              <span className="visible w-auto">0.00</span>
              USD
            </h3>
            <button
              type="button"
              aria-label="What is total balance?"
              className="text-[#454745] bg-transparent border-0 p-0"
            >
              <div className="relative select-none text-[#0e0f0c] min-w-6 w-6 h-6 text-xs rounded-full">
                <div className="flex items-center justify-center  text-[#0e0f0c] h-6 w-6 max-h-full max-w-full overflow-hidden rounded-full">
                  <span className="max-h-full max-w-full text-[#163300]">
                    <HelpCircleIcon className="h-6 w-6" />
                  </span>
                </div>
              </div>
            </button>
          </div>
        </div>
      </div>

      <div className="relative flex flex-col gap-4">
        <div className="flex flex-wrap justify-start gap-2 overflow-x-scroll scroll-smooth snap-x snap-mandatory scrollbar-none">
          <div className="flex flex-[0_0_auto]">
            <button
              type="button"
              aria-expanded="false"
              className="flex items-center justify-center h-8 px-3 bg-[#9fe870] text-[#163300] font-semibold rounded-full"
            >
              Send
              <span className="ml-2 block rotate-180 text-[#163300] pointer-events-none">
                <ArrowLeftIcon className="h-4 w-4" />
              </span>
            </button>
          </div>

          <div className="flex flex-[0_0_auto]">
            <button
              type="button"
              aria-expanded="false"
              className="flex items-center justify-center h-8 px-3 bg-gray-100 text-[#1e40af] font-semibold rounded-full"
            >
              Add Money
              <span className="ml-2 block rotate-180 text-[#163300] pointer-events-none">
                <PlusIcon className="h-4 w-4" />
              </span>
            </button>
          </div>
        </div>
      </div>

      <div className="relative flex flex-col gap-4">
        <div className="will-change-transform">
          <div className="relative">
            <div className="scroll-smooth snap-x snap-mandatory scroll-pl-8 scroll-pr-8 flex justify-start gap-3 overflow-x-auto scrollbar-none">
              {/* AED Balance Card */}
              <a
                aria-label="AED balance. 0.00 AED."
                href="/link?urn=urn%3Awise%3Abalances%3A97402948"
                className="flex flex-col justify-between h-52 w-64 flex-[0_0_256px] bg-gray-100 text-[#163300] font-semibold rounded-2xl p-4 snap-center"
              >
                <div className="flex items-center">
                  <div className="self-start mr-2">
                    <div className="relative select-none min-w-12 w-12 h-12 rounded-full">
                      <div className="flex items-center justify-center bg-[rgba(22,51,0,0.08)] h-12 w-12 overflow-hidden rounded-full">
                        <img
                          src="https://wise.com/web-art/assets/flags/aed.svg"
                          loading="lazy"
                          alt=""
                          width="64"
                          height="64"
                          className="h-12 w-12 object-cover rounded-full shadow-[0_0_0_1px_rgba(14,15,12,0.12)]"
                        />
                      </div>
                    </div>
                  </div>
                  <h4 className="text-[#454745] leading-[24.3px] -tracking-[0.252px] text-lg font-semibold">
                    AED
                  </h4>
                </div>
                <div className="flex items-center">
                  <div className="self-start mr-2">
                    <div className="relative select-none min-w-12 w-12 h-12 rounded-full">
                      <div className="flex items-center justify-center bg-[rgba(22,51,0,0.08)] h-12 w-12 overflow-hidden rounded-full">
                        <img
                          src="/images/test/peak_icon.png"
                          loading="lazy"
                          alt=""
                          width="64"
                          height="64"
                          className="h-12 w-12 object-cover rounded-full shadow-[0_0_0_1px_rgba(14,15,12,0.12)] bg-white"
                        />
                      </div>
                    </div>
                  </div>
                  <h4 className="text-[#454745] leading-[24.3px] -tracking-[0.252px] text-lg font-semibold">
                    Peak Revival X Income
                  </h4>
                </div>
                <div className="max-w-full text-left text-[#0e0f0c]">
                  <div className="text-sm leading-[21.7px] -tracking-[0.084px] flex items-center"></div>
                  <div className="text-[#0e0f0c] leading-[27.5px] text-[22px] whitespace-nowrap mt-1">
                    <span className="visible w-auto">0.00</span>
                  </div>
                </div>
              </a>

              {/* SAR Balance Card */}
              <a
                aria-label="EUR balance. 0.00 EUR."
                href="/link?urn=urn%3Awise%3Abalances%3A97403187"
                className="flex flex-col justify-between h-52 w-64 flex-[0_0_256px] bg-gray-100 text-[#163300] font-semibold rounded-2xl p-4 snap-center"
              >
                <div className="flex items-center">
                  <div className="self-start mr-2">
                    <div className="relative select-none min-w-12 w-12 h-12 rounded-full">
                      <div className="flex items-center justify-center bg-[rgba(22,51,0,0.08)] h-12 w-12 overflow-hidden rounded-full">
                        <img
                          src="https://wise.com/web-art/assets/flags/sar.svg"
                          loading="lazy"
                          alt=""
                          width="64"
                          height="64"
                          className="h-12 w-12 object-cover rounded-full shadow-[0_0_0_1px_rgba(14,15,12,0.12)] bg-white"
                        />
                      </div>
                    </div>
                  </div>
                  <h4 className="text-[#454745] leading-[24.3px] -tracking-[0.252px] text-lg font-semibold">
                    SAR
                  </h4>
                </div>
                <div className="flex items-center">
                  <div className="self-start mr-2">
                    <div className="relative select-none min-w-12 w-12 h-12 rounded-full">
                      <div className="flex items-center justify-center bg-[rgba(22,51,0,0.08)] h-12 w-12 overflow-hidden rounded-full">
                        <img
                          src="/images/test/peak_icon.png"
                          loading="lazy"
                          alt=""
                          width="64"
                          height="64"
                          className="h-12 w-12 object-cover rounded-full shadow-[0_0_0_1px_rgba(14,15,12,0.12)] bg-white"
                        />
                      </div>
                    </div>
                  </div>
                  <h4 className="text-[#454745] leading-[24.3px] -tracking-[0.252px] text-lg font-semibold">
                    Peak Revival X Income
                  </h4>
                </div>
                <div className="max-w-full text-left text-[#0e0f0c]">
                  <div className="text-sm leading-[21.7px] -tracking-[0.084px] flex items-center"></div>
                  <div className="text-[#0e0f0c] leading-[27.5px] text-[22px] whitespace-nowrap mt-1">
                    <span className="visible w-auto">0.00</span>
                  </div>
                </div>
              </a>

              {/* USD Balance Card */}
              <a
                aria-label="USD balance. 0.00 USD."
                href="/link?urn=urn%3Awise%3Abalances%3A97402004"
                className="flex flex-col justify-between h-52 w-64 flex-[0_0_256px] bg-gray-100 text-[#163300] font-semibold rounded-2xl p-4 snap-center"
              >
                <div className="flex items-center">
                  <div className="self-start mr-2">
                    <div className="relative select-none min-w-12 w-12 h-12 rounded-full">
                      <div className="flex items-center justify-center bg-[rgba(22,51,0,0.08)] h-12 w-12 overflow-hidden rounded-full">
                        <img
                          src="https://wise.com/web-art/assets/flags/usd.svg"
                          loading="lazy"
                          alt=""
                          width="64"
                          height="64"
                          className="h-12 w-12 object-cover rounded-full shadow-[0_0_0_1px_rgba(14,15,12,0.12)]"
                        />
                      </div>
                    </div>
                  </div>
                  <h4 className="text-[#454745] leading-[24.3px] -tracking-[0.252px] text-lg font-semibold">
                    USD
                  </h4>
                </div>
                {/* <div className="flex items-center">
                  <div className="self-start mr-2">
                    <div className="relative select-none min-w-12 w-12 h-12 rounded-full">
                      <div className="flex items-center justify-center bg-[rgba(22,51,0,0.08)] h-12 w-12 overflow-hidden rounded-full">
                        <img
                          src="https://framerusercontent.com/assets/C6WW8gfSINClrUIpylKDVLbIUx0.png"
                          loading="lazy"
                          alt=""
                          width="64"
                          height="64"
                          className="h-12 w-12 object-cover rounded-full shadow-[0_0_0_1px_rgba(14,15,12,0.12)]"
                        />
                      </div>
                    </div>
                  </div>
                  <h4 className="text-[#454745] leading-[24.3px] -tracking-[0.252px] text-lg font-semibold">
                    Glych Income
                  </h4>
                </div> */}
                <div className="max-w-full text-left text-[#0e0f0c]">
                  <div className="text-sm leading-[21.7px] -tracking-[0.084px] flex items-center"></div>
                  <div className="text-[#0e0f0c] leading-[27.5px] text-[22px] whitespace-nowrap mt-1">
                    <span className="visible w-auto">0.00</span>
                  </div>
                </div>
              </a>

              {/* Add Balance Card */}
              <a
                aria-label="Open a balance."
                href="/link?urn=urn%3Awise%3Abalances%3F%3Daction%3Dopen"
                className="flex flex-col justify-between h-52 w-64 flex-[0_0_256px] bg-[rgba(22,51,0,0.02)] text-[#163300] font-semibold rounded-2xl p-4 snap-center border-2 border-dashed border-[#0e0f0c1f]"
              >
                <div className="flex items-center">
                  <div className="self-start mr-2">
                    <div className="relative select-none text-[#0e0f0c] min-w-12 w-12 h-12 text-2xl rounded-full">
                      <div className="flex items-center justify-center bg-[rgba(22,51,0,0.08)] h-12 w-12 overflow-hidden rounded-full">
                        <div className="text-[#0e0f0c]">
                          <PlusIcon className="h-6 w-6" />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="max-w-full text-left text-[#0e0f0c]">
                  <div className="text-sm leading-[21.7px] -tracking-[0.084px] flex items-center">
                    <span className="text-[#454745]">
                      Add another currency to your account.
                    </span>
                  </div>
                </div>
              </a>
            </div>

            <button
              type="button"
              tabIndex={-1}
              className="flex items-center justify-center h-12 w-12 bg-[#9fe870] text-[#163300] font-semibold rounded-full absolute top-[104px] right-4 -translate-y-6 opacity-0 transition-opacity duration-200"
            >
              <span className="block rotate-90 text-[#163300]">
                <ArrowUpRightIcon className="h-4 w-4" />
              </span>
            </button>
          </div>
        </div>
      </div>

      <div className="relative flex flex-col gap-2 md:gap-4 px-0 md:px-2">
        {/* Header */}
        <div className="flex items-center justify-between mt-2 md:mt-4">
          <h3 className="text-base md:text-xl font-semibold text-gray-900 m-0">
            Transactions
          </h3>
          <a
            href="/all-transactions"
            className="text-[#163300] font-semibold hover:text-opacity-80"
          >
            <button
              type="button"
              className="text-xs md:text-sm px-2 md:px-3 py-1 underline underline-offset-2 md:underline-offset-4"
            >
              See all
            </button>
          </a>
        </div>

        {/* Transaction Items */}
        <div>
          <a
            role="button"
            href="/link?urn=urn%3Awise%3Aactivities%3ATU9ORVRBUllfQUNUSVZJVFk6OjI3MDMyNDUxOjpCQU5LX0RFVEFJTFNfT1JERVI6OjEyNzQ3Njk2"
            className="grid grid-cols-[36px_1fr_auto] md:grid-cols-[48px_1fr_auto] gap-2 md:gap-4 hover:bg-gray-50 rounded-lg p-2 md:p-4 text-gray-900"
          >
            <div className="relative select-none min-w-9 w-9 h-9 md:min-w-12 md:w-12 md:h-12 rounded-full">
              <div className="flex items-center justify-center bg-gray-100 h-full w-full overflow-hidden rounded-full">
                <div className="text-gray-600">
                  <UserIcon className="h-4 w-4 md:h-6 md:w-6" />
                </div>
              </div>
            </div>

            <div className="flex flex-col justify-center gap-0.5 md:gap-1 flex-1">
              <p className="text-sm md:text-base font-semibold m-0">
                For <span className="text-gray-600">your account details</span>
              </p>
              <p className="text-xs md:text-sm text-gray-600 m-0">Cancelled</p>
            </div>

            <div className="flex items-center justify-end">
              <div className="text-right">
                <p className="text-sm md:text-base font-semibold m-0">
                  <span>31</span> USD
                </p>
              </div>
            </div>
          </a>

          <a
            role="button"
            href="/link?urn=urn%3Awise%3Aactivities%3ATU9ORVRBUllfQUNUSVZJVFk6OjI3MDMyNDUxOjpUUkFOU0ZFUjo6MTE4MDY4MjEzNg%3D%3D"
            className="grid grid-cols-[36px_1fr_auto] md:grid-cols-[48px_1fr_auto] gap-2 md:gap-4 hover:bg-gray-50 rounded-lg p-2 md:p-4 text-gray-900"
          >
            <div className="relative select-none min-w-9 w-9 h-9 md:min-w-12 md:w-12 md:h-12 rounded-full">
              <div className="flex items-center justify-center bg-gray-100 h-full w-full overflow-hidden rounded-full">
                <div className="text-gray-600">
                  <RefreshCcwIcon className="h-4 w-4 md:h-6 md:w-6" />
                </div>
              </div>
            </div>

            <div className="flex flex-col justify-center gap-0.5 md:gap-1 flex-1">
              <p className="text-sm md:text-base font-semibold m-0">
                To <span className="text-gray-600">your USD balance</span>
              </p>
              <p className="text-xs md:text-sm text-gray-600 m-0">
                Cancelled · Aug 26
              </p>
            </div>

            <div className="flex items-center justify-end">
              <div className="text-right">
                <p className="text-sm md:text-base font-semibold m-0">
                  <span>100</span> USD
                </p>
                <p className="text-xs md:text-sm text-gray-600 mt-0.5 md:mt-1">
                  <span>102.27</span> USD
                </p>
              </div>
            </div>
          </a>

          <a
            role="button"
            href="/link?urn=urn%3Awise%3Aactivities%3ATU9ORVRBUllfQUNUSVZJVFk6OjI3MDMyNDUxOjpUUkFOU0ZFUjo6MTE4MDY3NjU4Nw%3D%3D"
            className="grid grid-cols-[36px_1fr_auto] md:grid-cols-[48px_1fr_auto] gap-2 md:gap-4 hover:bg-gray-50 rounded-lg p-2 md:p-4 text-gray-900"
          >
            <div className="relative select-none min-w-9 w-9 h-9 md:min-w-12 md:w-12 md:h-12 rounded-full">
              <div className="flex items-center justify-center bg-gray-100 h-full w-full overflow-hidden rounded-full">
                <div className="text-gray-600">
                  <SendIcon className="h-4 w-4 md:h-6 md:w-6" />
                </div>
              </div>
            </div>

            <div className="flex flex-col justify-center gap-0.5 md:gap-1 flex-1">
              <p className="text-sm md:text-base font-semibold m-0">
                To <span className="text-gray-600">your USD balance</span>
              </p>
              <p className="text-xs md:text-sm text-gray-600 m-0">
                Cancelled · Aug 13
              </p>
            </div>

            <div className="flex items-center justify-end">
              <div className="text-right">
                <p className="text-sm md:text-base font-semibold m-0">
                  <span>100</span> USD
                </p>
                <p className="text-xs md:text-sm text-gray-600 mt-0.5 md:mt-1">
                  <span>102.27</span> USD
                </p>
              </div>
            </div>
          </a>
        </div>
      </div>
    </div>
  );
}
