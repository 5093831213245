/* eslint-disable no-unused-vars */
import ReactDOM from "react-dom";
import React, { useCallback, useState } from "react";
import ReactPaginate from "react-paginate";
import { useDispatch } from "react-redux";
import { NavLink, useNavigate } from "react-router-dom";
import types from "../../../../redux/types";
import CustomSelect from "../../../Base/Filters/CustomSelect";
import CustomMultiRangeSlider from "../../../Base/Filters/CustomMultiRangeSlider";
import Title from "../../../Base/Title";
import BreadCrumbs from "../../../Base/BreadCrumbs";

const ZoomImage = ({ src, setZoomImage }) => {
  return ReactDOM.createPortal(
    <div className="alert">
      <div className="alert-picture">
        <div
          onClick={() => setZoomImage(false)}
          className="alert-picture__close"
        ></div>
        <img src={src} className="img" />
      </div>
    </div>,
    document.getElementById("alert")
  );
};

const Row = ({ product }) => {
  const navigate = useNavigate();
  const [zoomImage, setZoomImage] = useState(false);
  const dispatch = useDispatch();

  const onDelete = useCallback((e) => {
    e.stopPropagation();
    dispatch({
      type: types.SHOW_MODAL,
      payload: {
        name: "ConfirmDialog",
        text: "Are you sure you want to delete this product?",
        callbacks: {
          finally: () => {},
          success: () => {},
        },
      },
    });
  }, []);

  const showImage = useCallback((e) => {
    e.stopPropagation();
    setZoomImage(true);
  }, []);

  return (
    <>
      {zoomImage && (
        <ZoomImage src={product.imageUrl} setZoomImage={setZoomImage} />
      )}

      {/* Desktop View */}
      <tr
        className={`hidden md:table-row ${
          product.status === "localized"
            ? "hover:bg-gray-50 cursor-pointer"
            : "bg-gray-50"
        }`}
        onClick={() => product.status === "localized" && navigate(product.id)}
      >
        <td className="px-6 py-4 whitespace-nowrap">
          <div className="flex items-center gap-3">
            <div className="flex-shrink-0 h-10 w-10">
              <img
                src={product.imageUrl}
                alt={product.name}
                className="h-10 w-10 rounded-full object-cover cursor-pointer"
                onClick={showImage}
              />
            </div>
            <span className="text-sm text-gray-900">{product.name}</span>
          </div>
        </td>
        <td className="px-6 py-4">
          <div className="text-sm text-gray-500 truncate max-w-xs">
            {product.description}
          </div>
        </td>
        <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
          ${product.price.toFixed(2)}
        </td>
        <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
          {product.inventory} Units
        </td>
        <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
          {product.category || "-"}
        </td>
        <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
          {product.countries.join(", ")}
        </td>
        <td className="px-6 py-4 whitespace-nowrap">
          <span
            className={`px-2 inline-flex text-xs leading-5 font-semibold rounded-full ${
              product.status === "approved"
                ? "bg-green-100 text-green-800"
                : "bg-yellow-600 text-yellow-100"
            }`}
          >
            {product.status}
          </span>
        </td>
        <td className="px-6 py-4 whitespace-nowrap text-right text-sm font-medium"></td>
      </tr>

      {/* Mobile View */}
      <div
        className={`md:hidden border rounded-lg shadow-sm mb-4 ${
          product.status === "localized"
            ? "bg-white cursor-pointer"
            : "bg-gray-50"
        }`}
        onClick={() => product.status === "localized" && navigate(product.id)}
      >
        {/* Header with Image and Name */}
        <div className="p-4 border-b">
          <div className="flex items-center gap-3">
            <div className="flex-shrink-0 h-12 w-12">
              <img
                src={product.imageUrl}
                alt={product.name}
                className="h-full w-full rounded-full object-cover cursor-pointer"
                onClick={showImage}
              />
            </div>
            <span className="text-base font-medium text-gray-900">
              {product.name}
            </span>
          </div>
        </div>

        {/* Product Details */}
        <div className="divide-y">
          <div className="flex justify-between p-4">
            <span className="text-sm font-medium text-gray-500">
              Description
            </span>
            <span className="text-sm text-gray-900 text-right">
              {product.description}
            </span>
          </div>

          <div className="flex justify-between p-4">
            <span className="text-sm font-medium text-gray-500">Cost</span>
            <span className="text-sm text-gray-900">
              ${product.price.toFixed(2)}
            </span>
          </div>

          <div className="flex justify-between p-4">
            <span className="text-sm font-medium text-gray-500">Inventory</span>
            <span className="text-sm text-gray-900">
              {product.inventory} Units
            </span>
          </div>

          <div className="flex justify-between p-4">
            <span className="text-sm font-medium text-gray-500">Countries</span>
            <span className="text-sm text-gray-900">
              {product.countries.join(", ")}
            </span>
          </div>

          <div className="flex justify-between p-4">
            <span className="text-sm font-medium text-gray-500">Status</span>
            <span
              className={`px-2 inline-flex text-xs leading-5 font-semibold rounded-full ${
                product.status === "localized"
                  ? "bg-green-800 text-green-100"
                  : "bg-yellow-600 text-yellow-100"
              }`}
            >
              {product.status}
            </span>
          </div>
        </div>

        {/* Actions */}
        <div className="p-4 border-t">
          {product.status === "localized" && (
            <div className="text-sm text-gray-500 text-center italic">
              Click for product details
            </div>
          )}
        </div>
      </div>
    </>
  );
};

const ProductsContainer = () => {
  const [countRows, setCountRows] = useState(10);
  const [page, setPage] = useState(1);

  const [statuses, setStatuses] = useState([
    { value: "new", label: "New", status: true },
    { value: "on_review", label: "In Review", status: true },
    { value: "need_info", label: "Need info", status: true },
    { value: "rejected", label: "Rejected", status: true },
    { value: "approved", label: "Approved", status: true },
  ]);

  const [currencies, setCurrencies] = useState([
    { value: "usd", label: "USD", status: true },
    { value: "eur", label: "EUR", status: true },
    { value: "uah", label: "UAH", status: true },
  ]);

  const productTurk = {
    id: "25",
    name: "Turkesterone & Tongkat Ali",
    description: "Turkesterone & Tongkat Ali Supplement 1000mg Per Serving",
    imageUrl: "/images/test/mainnewturk.png",
    price: 54.0,
    inventory: 50,
    category: "Category",
    countries: ["KSA", "UAE"],
    status: "localized",
  };

  const productFadogia = {
    id: "26",
    name: "Fadogia Agrestis",
    description: "Supplement 1000mg Per Serving",
    imageUrl: "/images/test/fadogia.webp",
    price: 54.0,
    inventory: 0,
    category: "Category",
    countries: ["KSA", "UAE"],
    status: "in review",
  };

  const data = [productTurk, productFadogia];

  return (
    <Products
      data={data}
      countRows={countRows}
      page={page}
      setPage={setPage}
      statuses={statuses}
      setStatuses={setStatuses}
      currencies={currencies}
      setCurrencies={setCurrencies}
    />
  );
};

const Products = ({
  data,
  countRows,
  page,
  setPage,
  statuses,
  setStatuses,
  currencies,
  setCurrencies,
}) => {
  return (
    <div className="w-full">
      <BreadCrumbs
        links={[
          {
            to: "/profile/products",
            name: "Products",
          },
        ]}
      />

      <Title title="Products">
        <NavLink
          to="add"
          className="input input--btn input--btn--plus opacity-50 pointer-events-none"
          onClick={(e) => e.preventDefault()}
        >
          Add Product
        </NavLink>
      </Title>

      {/* Filters Section */}
      <div className="flex flex-col md:flex-row gap-4 px-1 py-4">
        <div className="flex flex-col md:flex-row gap-4 w-full">
          <CustomSelect
            name={"Status"}
            value={statuses}
            setValue={setStatuses}
          />
          <CustomSelect
            name={"Currency"}
            value={currencies}
            setValue={setCurrencies}
          />
        </div>
      </div>

      {/* Table/Cards Section */}
      <div className="w-full">
        {/* Desktop Table */}
        <table className="hidden md:table min-w-full divide-y divide-gray-200">
          <thead className="bg-gray-50">
            <tr>
              <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                Name
              </th>
              <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                Description
              </th>
              <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                Price
              </th>
              <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                Inventory
              </th>
              <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                Countries
              </th>
              <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                Status
              </th>
              <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"></th>
            </tr>
          </thead>
          <tbody className="bg-white divide-y divide-gray-200">
            <Row product={data[0]} />
            <Row product={data[1]} />
          </tbody>
        </table>
      </div>

      {/* Mobile Cards */}
      <div className="md:hidden px-1">
        <Row product={data[0]} />
        <Row product={data[1]} />
        {/* Add more rows as needed */}
      </div>

      {/* Pagination Section */}
      {/* <div className="flex flex-col md:flex-row justify-between items-center gap-4 px-1 py-4">
        <div className="flex items-center gap-2">
          <p className="text-sm text-gray-600">View</p>
          <input
            type="text"
            className="w-16 px-2 py-1 border rounded"
            value={countRows}
            disabled
          />
          <p className="text-sm text-gray-600">records per page</p>
        </div>
        <div className="w-full md:w-auto">
          <ReactPaginate
            nextLabel="Next"
            initialPage={page - 1}
            onPageChange={(e) => {
              setPage(e.selected + 1);
            }}
            pageRangeDisplayed={window.innerWidth <= 768 ? 2 : 5}
            pageCount={data?.metadata?.totalPages || 1}
            previousLabel="Prev"
            renderOnZeroPageCount={null}
            containerClassName="flex gap-2 items-center justify-center"
            pageClassName="px-3 py-1 border rounded hover:bg-gray-50"
            pageLinkClassName="text-gray-600"
            activeClassName="bg-blue-50 text-blue-600"
            previousClassName="px-3 py-1 border rounded hover:bg-gray-50"
            nextClassName="px-3 py-1 border rounded hover:bg-gray-50"
            disabledClassName="opacity-50 cursor-not-allowed"
          />
        </div>
      </div> */}
    </div>
  );
};

export default React.memo(ProductsContainer);
