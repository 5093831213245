import { Field, Formik } from "formik";
import React, { useCallback, useState } from "react";
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import { NavLink } from "react-router-dom";
import types from "../../../../redux/types";
import { Input } from "../../../../utils/formcontrol";
import Yup from "../../../../utils/validators";
import Preloader from "../../../Base/Preloader";

const SignInContainer = () => {
  const dispatch = useDispatch();
  const [error, setError] = useState("");
  const { loader } = useSelector((state) => state.auth);

  const onSubmit = useCallback((data) => {
    setError(false);

    dispatch({
      type: types.SIGN_IN_START,
      payload: {
        callbackSuccess: () => {},
        callbackError: (err) => setError(err),
        data,
      },
    });
  }, []);

  const initialValues = {
    email: "",
    password: "",
  };

  const validationSchema = Yup.object().shape({
    email: Yup.string().required("Field is required").email(),
    password: Yup.string().required("Field is required").zxcvbnType(),
  });

  return (
    <SignIn
      onSubmit={onSubmit}
      initialValues={initialValues}
      validationSchema={validationSchema}
      error={error}
      loader={loader}
    />
  );
};

const SignIn = ({
  onSubmit,
  initialValues,
  validationSchema,
  error,
  loader,
}) => (
  <>
    {loader && <Preloader />}

    <div className="box">
      <NavLink to={"/"} className="box-logo"></NavLink>

      <h2 className="box-title">Welcome to AmnaPay!</h2>
      <div className="form-wrapper">
        {error && (
          <div className="message-errror">
            <div className="message-errror__pic"></div>
            <p>
              {error === "Email and/or password is invalid" ? (
                <>Something wrong. Please try again</>
              ) : (
                error
              )}
            </p>
          </div>
        )}
        <Formik
          id="sign_in"
          enableReinitialize
          initialValues={{ ...initialValues }}
          validationSchema={validationSchema}
          onSubmit={onSubmit}
        >
          {({ handleSubmit, isValid, touched }) => (
            <form onSubmit={handleSubmit} name="sign_in" className="form">
              <Field
                type="text"
                title={"Email *"}
                name="email"
                component={Input}
                autoComplete="new-password"
              />

              <Field
                type="password"
                title={"Password *"}
                name="password"
                component={Input}
                autoComplete="new-password"
                forgot="true"
              />

              <input
                type="submit"
                value="Sign In"
                className="input input--btn"
                disabled={!(isValid && Object.keys(touched).length) || loader}
              />
              <div className="form__el-message form__el-message--leftside">
                Need an account?
                <NavLink to={"/"} className="link txt--blue">
                  Register
                </NavLink>
              </div>
            </form>
          )}
        </Formik>
      </div>
    </div>
  </>
);

export default React.memo(SignInContainer);
