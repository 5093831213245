import { ChevronDownIcon, ActivityIcon, LockIcon } from "lucide-react";
import React, { useState } from "react";

const CardDetails = ({ card, isExpanded, onToggle }) => {
  return (
    <div className="w-full bg-white rounded-lg shadow-md overflow-hidden mb-4">
      <div className="p-4 cursor-pointer hover:bg-gray-50" onClick={onToggle}>
        <div className="flex justify-between items-center">
          <div className="flex items-center space-x-4">
            <div className="h-2 w-2 rounded-full bg-green-600"></div>
            <div>
              <h3 className="text-[15px] font-medium">{card.name}</h3>
              <p className="text-xs text-gray-600">{card.holder}</p>
            </div>
          </div>
          <div className="flex items-center space-x-4">
            <div className="text-right">
              <p className="text-[15px] font-medium">${card.spent}</p>
              <p className="text-xs text-gray-600">Spent today</p>
            </div>
            <ChevronDownIcon
              className={`h-5 w-5 text-gray-400 transition-transform ${
                isExpanded ? "transform rotate-180" : ""
              }`}
            />
          </div>
        </div>
      </div>

      {isExpanded && (
        <div className="border-t">
          <div className="p-6">
            {/* Progress bar */}
            <div className="mb-4">
              <div className="relative h-4 w-full bg-gray-100 border border-gray-200 rounded shadow-inner">
                <div
                  className="absolute h-full bg-blue-500 rounded"
                  style={{ width: `${(card.spent / card.limit) * 100}%` }}
                ></div>
              </div>
              <div className="flex justify-between mt-2 text-xs text-gray-600">
                <span>${card.spent} spent</span>
                <span>${card.limit} limit</span>
              </div>
            </div>

            {/* Card Image */}
            <div className="flex justify-center mb-6">
              <div
                className="relative flex flex-col h-[175px] w-[280px] bg-contain bg-no-repeat rounded-xl p-4 shadow-lg"
                style={{
                  backgroundImage: `url(${card.image})`,
                }}
              >
                <div className="mt-auto">
                  <div className="text-[10px] sm:text-xs tracking-wide">
                    •••• •••• •••• {card.lastFour}
                  </div>
                  <div className="flex mt-2 space-x-4">
                    <div className="flex items-center">
                      <span className="text-[10px] sm:text-xs text-gray-500 mr-1">
                        EXP
                      </span>
                      <span className="text-[10px] sm:text-xs">••/••</span>
                    </div>
                    <div className="flex items-center">
                      <span className="text-[10px] sm:text-xs text-gray-500 mr-1">
                        CVC
                      </span>
                      <span className="text-[10px] sm:text-xs">•••</span>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            {/* Actions */}
            <div className="flex justify-between items-center border-t border-b py-4">
              <button className="flex items-center text-gray-700">
                <LockIcon className="h-4 w-4 mr-2 text-gray-400" />
                <span>Freeze</span>
              </button>
              <button className="flex items-center text-gray-700">
                <ActivityIcon className="h-4 w-4 mr-2 text-gray-400" />
                <span>Transactions</span>
              </button>
              <button className="flex items-center text-gray-700">
                <span>More</span>
                <ChevronDownIcon className="h-4 w-4 ml-2 text-gray-400" />
              </button>
            </div>

            {/* Card Details */}
            <div className="pt-4">
              <div className="space-y-4">
                <div className="flex justify-between">
                  <span className="text-gray-600">Daily spend limit</span>
                  <span className="font-medium">${card.limit}</span>
                </div>
                <div className="flex justify-between">
                  <span className="text-gray-600">Account</span>
                  <span className="font-medium">{card.account}</span>
                </div>
                <div className="flex justify-between">
                  <span className="text-gray-600">Card type</span>
                  <span className="font-medium">{card.type}</span>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default function WiseVirtualCard() {
  const [expandedCardId, setExpandedCardId] = useState(null);

  const cards = [
    {
      id: 1,
      name: "Meta Ads Debit Card",
      holder: "Nader Kutub",
      spent: 150,
      limit: 2500,
      lastFour: "2020",
      account: "Checking ••2652",
      type: "Virtual Debit",
      image:
        "https://cdn.mercury.com/builds/6492b0daa622f1d694e305dc226f913c3638ab03/icons/cards/revealable/debitVirtual.png",
    },
    {
      id: 2,
      name: "Business Card",
      holder: "Nader Kutub",
      spent: 500,
      limit: 5000,
      lastFour: "4242",
      account: "Business ••4242",
      type: "Virtual Debit",
      image:
        "https://cdn.mercury.com/builds/6492b0daa622f1d694e305dc226f913c3638ab03/icons/cards/revealable/debitVirtual.png",
    },
  ];

  return (
    <div className="w-full space-y-4">
      {cards.map((card) => (
        <CardDetails
          key={card.id}
          card={card}
          isExpanded={expandedCardId === card.id}
          onToggle={() =>
            setExpandedCardId(expandedCardId === card.id ? null : card.id)
          }
        />
      ))}
    </div>
  );
}
