import React from "react";

const DemoBanner = () => {
  return (
    <div className="w-full bg-orange-500 text-white py-2 px-4 text-center shadow-sm relative z-50">
      <p className="text-sm md:text-base font-medium">
        Demo Mode: Limited features and sample data.
      </p>
    </div>
  );
};

export default DemoBanner;
