import React from "react";
import { useParams } from "react-router-dom";
import BreadCrumbs from "../../../../Base/BreadCrumbs";
import Title from "../../../../Base/Title";
import RollBack from "../../../../Base/RollBack";
// Import the Card component
const Card = ({ title, items, blurb, rating }) => {
  const [isExpanded, setIsExpanded] = React.useState(false);

  const getBannerColor = (rating) => {
    switch (rating) {
      case "Poor":
        return "bg-red-500";
      case "Average":
        return "bg-yellow-500";
      case "Strong":
        return "bg-green-500";
      default:
        return "bg-blue-500";
    }
  };

  return (
    <section className="bg-white shadow-md rounded-lg relative">
      <div
        className={`w-full p-2 text-center text-white font-bold ${getBannerColor(
          rating
        )} rounded-t-lg`}
      >
        {rating}
      </div>
      <div className="p-4">
        <div className="flex justify-between items-center">
          <h2 className="text-xl font-bold">{title}</h2>
          <button
            onClick={() => setIsExpanded(!isExpanded)}
            className="text-gray-600 focus:outline-none"
          >
            {isExpanded ? "▲" : "▼"}
          </button>
        </div>

        {isExpanded && (
          <div className="mt-4">
            <ul className="space-y-4">
              {items.map((item, index) => (
                <li key={index}>
                  <p className="text-xl font-bold">{item.label}:</p>
                  <p className="text-gray-600">{item.value}</p>
                </li>
              ))}
            </ul>
            <p className="mt-4 text-gray-600">{blurb}</p>
          </div>
        )}
      </div>
    </section>
  );
};

const ProductReport = () => {
  const { id } = useParams();
  const reportData = {
    targetPrice: "$54.00",
    breakEvenCpa: "$9.0",
    deliveryRate: "22%",
    estimatedCogs: "$4",
    topCountries: [
      { country: "UAE", vatRule: "NO VAT under $100k/year" },
      { country: "KSA", vatRule: "NO VAT under $100k/year" },
    ],
    profitScale: [
      { cpa: "$8.0", orders: 1000, profit: "5.1%" },
      { cpa: "$7.0", orders: 1000, profit: "12.0%" },
      { cpa: "$6.0", orders: 1000, profit: "18.8%" },
    ],
  };

  // Market Analysis Data
  const marketData = [
    {
      title: "Market Overview",
      items: [
        {
          label: "Market Size",
          value:
            "The wellness supplements market in the Middle East and Africa was valued at approximately USD 10.55 billion in 2023 and is projected to reach USD 14.54 billion by 2030, growing at a CAGR of 4.69%.",
        },
        {
          label: "Growth Rate",
          value:
            "The digital health market in the UAE and KSA is expected to reach about USD 4 billion by 2026, indicating a significant opportunity for online health-related products and services.",
        },
        {
          label: "Top Categories",
          value:
            "Key product categories driving growth include vitamins, minerals, herbal extracts, and probiotics, with a notable demand for natural testosterone boosters and energy enhancers.",
        },
      ],
      blurb:
        "The UAE and KSA markets show significant growth potential in the wellness supplements sector, driven by increasing health awareness and demand for natural products.",
      rating: "Strong",
    },
    {
      title: "Demand and Opportunity",
      items: [
        {
          label: "Search Trends",
          value:
            "There has been a notable increase in online searches for natural supplements aimed at enhancing vitality and wellness, indicating growing consumer interest.",
        },
        {
          label: "Top Products",
          value:
            "Supplements containing Turkesterone and Tongkat Ali are gaining popularity due to their perceived benefits in supporting testosterone levels and overall health.",
        },
        {
          label: "Unmet Needs",
          value:
            "Consumers are seeking natural, effective supplements with transparent sourcing and scientifically backed benefits, presenting opportunities for brands to meet these demands.",
        },
      ],
      blurb:
        "The rising interest in natural health supplements presents a strong market potential for innovative sellers offering products like Turkesterone and Tongkat Ali.",
      rating: "Strong",
    },
    {
      title: "Competitor Analysis",
      items: [
        {
          label: "Top Competitors",
          value:
            "The market features a mix of established brands and emerging players, with no single entity dominating the space, allowing room for new entrants. There are cheaper Chinese brands that are selling for $10-20, but they are not as effective as the brands we are recommending. ",
        },
        {
          label: "Average Price",
          value:
            "Prices vary, with premium brands commanding higher prices due to perceived quality and efficacy. Make sure to price your product competitively to capture market share but also make sure to price it high enough to make a profit.",
        },
        {
          label: "Market Share",
          value:
            "The fragmented nature of the market provides opportunities for new brands to capture significant share through effective marketing and quality offerings.",
        },
      ],
      blurb:
        "Understanding the competitive landscape is crucial for effectively positioning your product in this growing market.",
      rating: "Average",
    },
    {
      title: "Financial Metrics",
      items: [
        {
          label: "Profit Margins",
          value:
            "Selling products at a $50 price point can result in slim profit margins due to costs associated with production, shipping, and marketing. To enhance profitability, consider offering bundled packages priced at $100 or more, which can increase the average order value and improve margins.",
        },
        {
          label: "Tax Implications",
          value:
            "Be aware that if annual revenues exceed approximately $100,000 per country, businesses become liable for import taxes and Value Added Tax (VAT) in both the UAE and KSA. These taxes can significantly impact profit margins, making it essential to factor them into pricing strategies.",
        },
        {
          label: "Subscription Models",
          value:
            "While subscription-based models for product refills are not yet widely adopted in the region, they are gaining traction. Implementing a subscription model can enhance customer retention and increase the Customer Lifetime Value (LTV), providing a more stable revenue stream.",
        },
      ],
      blurb:
        "To achieve sustainable profitability, consider strategies such as increasing the average order value through bundled offerings and exploring subscription models. Additionally, proactively manage tax obligations to mitigate their impact on profit margins.",
      rating: "Average",
    },
    {
      title: "Marketing Insights",
      items: [
        {
          label: "Cost Per Acquisition (CPA) Comparison",
          value:
            "In the United States, the average CPA for health and fitness supplements varies widely, with some companies reporting CPAs around $83, which can be optimized to lower figures with effective strategies. In contrast, the MENA region, including countries like Saudi Arabia and the United Arab Emirates, often experiences lower CPAs due to less saturated digital advertising markets. For instance, Saudi Arabia has an average Cost Per Click (CPC) that is 60% less than the US average, which can contribute to a lower CPA.",
        },
        {
          label: "Preferred Marketing Channels",
          value:
            "Social media platforms such as Instagram, TikTok, Snapchat, and YouTube are highly effective in engaging emotionally driven consumers in the MENA region. These platforms facilitate visual storytelling and influencer collaborations, resonating deeply with the target audience.",
        },
        {
          label: "Influencer Partnerships",
          value:
            "Collaborating with local influencers enhances brand credibility and emotional connection. Influencers create authentic content that aligns with the brand's values, fostering trust and engagement among their audience.",
        },
        {
          label: "Content Strategies",
          value:
            "Utilizing user-generated content (UGC) and storytelling techniques evokes emotions and builds a loyal community. Encouraging customers to share their experiences and featuring these stories in marketing campaigns humanizes the brand and strengthens emotional bonds.",
        },
        {
          label: "Emotional Branding",
          value:
            "Crafting a brand narrative that resonates with the cultural values and aspirations of consumers in the MENA region fosters deep emotional connections. This involves understanding the local culture and integrating relevant themes into the brand's messaging.",
        },
      ],
      blurb:
        "AmnaCart has sellers in the supplement space who have successfully achieved a $5 CPA and sustained high profit margins with the right marketing strategies.",
      rating: "Strong",
    },
    {
      title: "Customer Insights",
      items: [
        {
          label: "Average Rating",
          value:
            "Positive customer reviews are crucial for building brand credibility and encouraging purchases.",
        },
        {
          label: "Positive Reviews",
          value:
            "Encouraging satisfied customers to leave reviews can enhance brand reputation.",
        },
        {
          label: "Brand Loyalty",
          value:
            "Providing high-quality products and excellent customer service fosters brand loyalty and repeat purchases.",
        },
      ],
      blurb:
        "Customers value quality and trusted brands, as indicated by high ratings and loyalty metrics.",
      rating: "Strong",
    },
    {
      title: "Logistics and Supply Chain",
      items: [
        {
          label: "Cash on Delivery (COD) Dynamics",
          value:
            "COD remains a prevalent payment method in the UAE and KSA, accounting for a significant portion of e-commerce transactions. However, it presents challenges such as higher return-to-origin (RTO) rates, with studies indicating that nearly 20% of COD orders result in RTOs due to factors like incorrect addresses and customers declining delivery. To mitigate these issues, implementing address verification and customer confirmation processes is essential.",
        },
        {
          label: "Customer Tolerance for Delivery Times",
          value:
            "Customers in the region exhibit a reasonable tolerance for delivery times, often accepting standard delivery windows. Nonetheless, clear communication regarding expected delivery dates and proactive updates can enhance customer satisfaction and reduce the likelihood of order cancellations.",
        },
        {
          label: "Address Verification and Customer Service",
          value:
            "Implementing robust address verification processes is crucial to reduce failed deliveries. This can involve customer service teams contacting customers to confirm address details prior to dispatch. Additionally, providing responsive customer support throughout the delivery process can help address any issues promptly, thereby improving delivery success rates.",
        },
      ],
      blurb:
        "AmnaCart is managing COD orders, understanding customer expectations for delivery times, and implementing thorough address verification processes are critical for optimizing logistics and supply chain operations in the UAE and KSA markets.",
      rating: "AMNA Simplified",
    },
    {
      title: "Legal and Compliance",
      items: [
        {
          label: "Import Tax",
          value:
            "Import duties are optional for businesses generating less than ~$100K per year in revenue per country.",
        },
        {
          label: "VAT",
          value:
            "VAT registration is optional for businesses with annual revenue below ~$100K in the UAE and KSA.",
        },
        {
          label: "Required Certifications",
          value:
            "Ensure products meet all regulatory requirements, including health certifications and labeling standards.",
        },
      ],
      blurb:
        "AmnaCart helps you navigate import taxes, VAT, and required certifications to ensure smooth market entry and regulatory compliance.",
      rating: "AMNA Simplified",
    },
    {
      title: "Payment Insights",
      items: [
        {
          label: "Preferred Payment Methods",
          value:
            "Cash on Delivery (COD) remains a significant payment method in the region, though its usage has declined from 41% in 2020 to 20% in 2023. Postpay and digital payments are also gaining traction.",
        },
        {
          label: "Fraud Risk",
          value:
            "Implementing robust security measures is essential to protect against fraud and build customer trust.",
        },
        {
          label: "Payment Conversion Rate",
          value:
            "Streamlined checkout processes contribute to higher conversion rates.",
        },
      ],
      blurb:
        "AmnaCart adopts diverse payment methods and ensures secure transactions to boost conversion rates.",
      rating: "AMNA Simplified",
    },
    {
      title: "Risk Analysis",
      items: [
        {
          label: "Customs Regulations",
          value:
            "While Turkesterone and Tongkat Ali are not currently on the banned substances list in the UAE and KSA, customs authorities may still hold shipments for additional inspections or documentation.",
        },
        {
          label: "Legal Changes",
          value:
            "The classification of supplements can change quickly. Regulations on performance-enhancing and herbal supplements are under periodic review, meaning Turkesterone or Tongkat Ali could face new restrictions in the future.",
        },
        {
          label: "Halal Certification",
          value:
            "Currently, Halal certification is not required for supplements in KSA and UAE. However, future regulations may mandate Halal certification, requiring compliance with local food and drug authorities.",
        },
      ],
      blurb:
        "AmnaCart monitors regulatory risks and informs you of any changes to the legal landscape.",
      rating: "AMNA Simplified",
    },
  ];

  return (
    <>
      <RollBack />
      <BreadCrumbs
        links={[
          {
            to: "/profile/products",
            name: "Products",
          },
          {
            to: `/profile/products/${id}`,
            name: "Product",
          },
          {
            to: `/profile/products/${id}/report`,
            name: "Report",
          },
        ]}
      />

      <Title title="Product Market Report" />

      <div className="md:px-6 min-h-screen">
        {/* Stats Grid */}
        <div className="grid grid-cols-1 md:grid-cols-4 gap-4 mb-6">
          <div className="bg-white shadow-md p-4 rounded-lg">
            <h3 className="text-gray-500 text-sm">Target Price</h3>
            <p className="text-2xl font-bold">{reportData.targetPrice}</p>
          </div>
          <div className="bg-white shadow-md p-4 rounded-lg">
            <h3 className="text-gray-500 text-sm">Break Even CPA</h3>
            <p className="text-2xl font-bold">{reportData.breakEvenCpa}</p>
          </div>
          <div className="bg-white shadow-md p-4 rounded-lg">
            <h3 className="text-gray-500 text-sm">Delivery Success Rate</h3>
            <p className="text-2xl font-bold">{reportData.deliveryRate}</p>
          </div>
          <div className="bg-white shadow-md p-4 rounded-lg">
            <h3 className="text-gray-500 text-sm">Estimated COGS</h3>
            <p className="text-2xl font-bold">{reportData.estimatedCogs}</p>
          </div>
        </div>

        {/* Charts Section */}
        <div className="grid grid-cols-1 md:grid-cols-2 gap-6 mb-6">
          <div className="bg-white shadow-md p-4 rounded-lg">
            <h3 className="text-lg font-semibold mb-4">Profit Scale</h3>
            <p className="text-gray-600 mb-4">
              These profit calculations factor in all fees and include an
              average return rate of 3%. No VAT or Import Tax is included as
              sales are under the annual threshold.
            </p>
            <table className="w-full">
              <thead>
                <tr className="text-gray-500">
                  <th className="text-left py-2">CPA</th>
                  <th className="text-right">Orders</th>
                  <th className="text-right">Profit %</th>
                </tr>
              </thead>
              <tbody>
                {reportData.profitScale.map((stat) => (
                  <tr key={stat.month} className="border-t">
                    <td className="py-2">{stat.cpa}</td>
                    <td className="text-right">{stat.orders}</td>
                    <td className="text-right">{stat.profit}</td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>

          <div className="bg-white shadow-md p-4 rounded-lg">
            <h3 className="text-lg font-semibold mb-4">Target Countries</h3>
            <div className="space-y-4">
              {reportData.topCountries.map((country) => (
                <div
                  key={country.country}
                  className="flex justify-between items-center"
                >
                  <span>{country.country}</span>
                  <span className="font-semibold">{country.vatRule}</span>
                </div>
              ))}
            </div>
          </div>
        </div>

        <Title title="Market Analysis" />

        {/* Market Analysis Cards */}
        <div className="grid grid-cols-1 md:grid-cols-2 gap-6 mt-6 mb-6">
          {marketData.map((data, index) => (
            <Card key={index} {...data} />
          ))}
        </div>
      </div>
    </>
  );
};

export default ProductReport;
