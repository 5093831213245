/* eslint-disable no-unused-vars */
import classNames from "classnames";
import React from "react";
import { useEffect } from "react";
import { useState } from "react";
import { useSelector } from "react-redux";
import { NavLink, useLocation } from "react-router-dom";
import Settings from "./Settings";
import { Home, CreditCard, User, Wallet, List } from "lucide-react";

const LeftBarContainer = () => {
  const { role } = useSelector((state) => state.auth.data);
  const sideBarCollapsed = localStorage.getItem("sidebar-collapsed");
  const [collapse, setCollapse] = useState(sideBarCollapsed ? false : true);

  const handleToggler = () => {
    if (collapse) {
      setCollapse(false);
      localStorage.setItem("sidebar-collapsed", true);
      return;
    }

    setCollapse(true);
    localStorage.removeItem("sidebar-collapsed");
  };

  const sellerRoutes = {
    profile: [
      {
        name: "Home",
        path: "/profile",
        image: "home",
      },
      // {
      //   name: "Transactions",
      //   path: "/profile/transactions",
      //   image: "transactions",
      // },
      {
        name: "Payments",
        path: "/profile/payments",
        image: "payments",
      },
      {
        name: "Cards",
        path: "/profile/cards",
        image: "cards",
      },
      {
        name: "Account",
        path: "/profile/account",
        image: "account",
      },
      {
        name: "Products",
        path: "/profile/products",
        image: "products",
      },
    ],
    settings: [
      {
        name: "Personal profile",
        path: "/settings/personal-profile",
        image: "personal_profile",
      },
      {
        name: "Company profile",
        path: "/settings/company-profile",
        image: "company_profile",
      },
      {
        name: "Integrations",
        path: "/settings/integrations",
        image: "integrations",
      },
      {
        name: "API tokens",
        path: "/settings/api",
        image: "api_tokens",
      },
      {
        name: "Team",
        path: "/settings/team",
        image: "team",
      },
      {
        name: "Notification",
        path: "/settings/notification",
        image: "notification",
      },
      {
        name: "Security",
        path: "/settings/security",
        image: "security",
      },
    ],
  };

  const underwriterRoutes = {
    profile: [
      {
        name: "Home",
        path: "/profile",
        image: "home",
      },
      {
        name: "Sellers applications",
        path: "/profile/sellers_applications",
        image: "phone",
      },
      {
        name: "Products",
        path: "/profile/products",
        image: "products",
      },
    ],
    settings: [
      {
        name: "Personal profile",
        path: "/settings/personal-profile",
        image: "personal_profile",
      },
    ],
  };

  const routes = role === "SELLER" ? sellerRoutes : underwriterRoutes;

  return (
    <>
      {/* LeftBar: Only visible on screens md (768px) and larger */}
      <div className="hidden md:flex">
        <LeftBar
          collapse={collapse}
          handleToggler={handleToggler}
          profile_routes={routes.profile}
          settings_routes={routes.settings}
        />
      </div>

      {/* BottomNavBar: Only visible on screens smaller than md */}
      <div className="block md:hidden">
        <BottomNavBar profile_routes={routes.profile} />
      </div>
    </>
  );
};

const LeftBar = ({
  collapse,
  handleToggler,
  profile_routes,
  settings_routes,
}) => {
  const { pathname } = useLocation();

  return (
    <aside
      className={classNames("main-menu", {
        "main-menu--isclose": collapse,
      })}
    >
      {(pathname.startsWith("/settings")
        ? settings_routes
        : profile_routes
      ).map((e, i) => (
        <NavLink
          end={e.path === "/profile"}
          key={i}
          to={e.path}
          className={({ isActive }) =>
            classNames("link main-menu__link", {
              "main-menu__link--active": isActive,
            })
          }
        >
          <img
            src={`/images/icons/profile/${e.image}.svg`}
            className="main-menu__link-block"
          />
          <img
            src={`/images/icons/profile/${e.image}_active.svg`}
            className="main-menu__link-block--active"
          />
          {!collapse && e.name}
        </NavLink>
      ))}

      <div className="main-menu__footer">
        <Settings collapse={collapse} />

        <NavLink
          to={"#"}
          className="link main-menu__link main-menu__footer-close"
          onClick={handleToggler}
        >
          <img
            src="/images/icons/profile/arrow.svg"
            className="main-menu__link-block"
          />
        </NavLink>
      </div>
    </aside>
  );
};

const BottomNavBar = ({ profile_routes }) => {
  return (
    <div className="fixed bottom-0 w-full bg-white border-t border-gray-200 shadow-lg md:hidden z-50 pb-5 pt-2.5">
      {/* md:hidden ensures this only appears on devices smaller than the 'md' breakpoint */}
      <div className="flex justify-around py-2">
        {profile_routes.map((e, i) => (
          <NavLink
            end={e.path === "/profile"}
            key={`${i}-bottom`}
            to={e.path}
            className={({ isActive }) =>
              classNames({
                "main-menu__link--active": isActive,
              })
            }
            style={{ borderRight: "0px" }}
          >
            <img
              src={`/images/icons/profile/${e.image}.svg`}
              className="main-menu__link-block"
            />
            <img
              src={`/images/icons/profile/${e.image}_active.svg`}
              className="main-menu__link-block--active"
            />
          </NavLink>
        ))}
        <div className="main-menu__footer">
          {/* <Settings collapse={true} /> */}
        </div>
      </div>
    </div>
  );
};

const NavItem = ({ icon, label }) => {
  return (
    <div className="flex flex-col items-center text-gray-600 hover:text-blue-500">
      {icon}
      <span className="text-xs">{label}</span>
    </div>
  );
};

export default React.memo(LeftBarContainer);
