import classNames from "classnames";
import React, { useEffect, useRef, useState } from "react";
import { NavLink } from "react-router-dom";
import { upperCase, lowerCase, oneNumber } from "./validators";

const FormControl = ({
  disabled,
  form,
  field,
  title,
  showErrors = true,
  type,
  setType,
  input_type,
  autoComplete,
  ...props
}) => {
  const ref = useRef();

  const errors = form.errors[field.name];
  const touched = form.touched[field.name];

  useEffect(() => {
    if (autoComplete === "new-password") {
      if (ref.current.value) {
        form.setFieldTouched(field.name, true);
        form.setFieldValue(field.name, ref.current.value);
      }
    }
  }, [ref.current?.value]);

  const [input, ...children] = Array.isArray(props.children)
    ? props.children
    : [props.children];

  return (
    <div
      className={classNames("form__el", {
        "form__el--filled": field.value,
        "txt--error-block": errors && touched && showErrors,
        "form__el--disabled": disabled,
      })}
    >
      {React.cloneElement(input, { ref })}

      {title && (
        <label className="form__el-label" htmlFor="firstName">
          {title}
        </label>
      )}

      {children && children}

      {type === "password" && (
        <div
          className={classNames("form__el-eye", {
            "form__el-eye--checked": input_type === "password",
          })}
          onClick={() =>
            setType(input_type === "password" ? "text" : "password")
          }
        ></div>
      )}

      {showErrors && errors && touched && (
        <>
          {type !== "password" && <div className="form__el-error-pic"></div>}
          <div className="txt--small txt--error">{errors}</div>
        </>
      )}

      {props.forgot && (
        <div className="form__el-message form__el-message--rightside">
          <NavLink
            to={"/auth/forgot-password"}
            className="link txt--blue"
            state={{ email: form.values?.email }}
          >
            Forgot password?
          </NavLink>
        </div>
      )}
    </div>
  );
};

export const Input = (props) => {
  const [type, setType] = useState(props.type);

  return (
    <FormControl {...props} input_type={type} setType={setType}>
      <input
        className="input input--txt form__el-input"
        {...props.field}
        type={type}
        inputMode={props.inputMode || null}
      />
    </FormControl>
  );
};

export const FalseInput = (props) => {
  const _props = {
    ...props,
    type: "text",
    field: {
      ...props.field,
      value: "********",
    },
  };

  return (
    <FormControl {..._props}>
      <input
        className="input input--txt form__el-input"
        {..._props.field}
        value={"********"}
      />
    </FormControl>
  );
};

export const InputNewPassword = (props) => {
  const [type, setType] = useState(props.type);

  const [values, setValues] = useState({
    upperCaseLetter: false,
    lowerCaseLetter: false,
    oneNumber: false,
    passLength: 0,
  });

  useEffect(() => {
    const password = props.form.values[props.field.name];

    const newState = {
      ...values,
      upperCaseLetter: !upperCase(password),
      lowerCaseLetter: !lowerCase(password),
      oneNumber: !oneNumber(password),
      passLength: +password?.length > 8 ? 8 : +password?.length,
    };

    setValues({
      ...values,
      ...newState,
    });
  }, [props?.form?.values]);

  return (
    <FormControl
      {...props}
      input_type={type}
      setType={setType}
      showErrors={false}
    >
      <input
        className="input input--txt form__el-input"
        {...props.field}
        type={type}
        inputMode={props.inputMode || null}
      />

      {props.form.values?.[props.field.name] && (
        <div className="form__el-message txt--small type-password">
          <p>
            At least one{" "}
            <span
              className={classNames({ "txt--green": values.upperCaseLetter })}
            >
              uppercase letter
            </span>
            ,
            <span
              className={classNames({ "txt--green": values.lowerCaseLetter })}
            >
              {" "}
              one lowercase letter
            </span>
            ,{" "}
            <span className={classNames({ "txt--green": values.oneNumber })}>
              one number
            </span>
          </p>
          <p>
            <span
              className={classNames({ "txt--green": values.passLength === 8 })}
            >
              {values.passLength}
            </span>
            /8
          </p>
        </div>
      )}
    </FormControl>
  );
};

export const InputCheckBox = ({ ...props }) => {
  return (
    <div className="form__el">
      <label className="label">
        <input
          className="input--checkbox"
          {...props.field}
          type={props.type}
          inputMode={props.inputMode || null}
        />
        {props.children}
      </label>
    </div>
  );
};
