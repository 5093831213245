import ReactDOM from "react-dom";
import React, { useCallback, useState } from "react";
import { useDispatch } from "react-redux";
import { useParams, Link } from "react-router-dom";
import types from "../../../../../redux/types";
import Title from "../../../../Base/Title";
import BreadCrumbs from "../../../../Base/BreadCrumbs";
import RollBack from "../../../../Base/RollBack";
import { processSteps } from "./processSteps";

const ZoomImage = ({ src, setZoomImage }) => {
  return ReactDOM.createPortal(
    <div className="alert">
      <div className="alert-picture">
        <div
          onClick={() => setZoomImage(false)}
          className="alert-picture__close"
        ></div>
        <img src={src} className="img" />
      </div>
    </div>,
    document.getElementById("alert")
  );
};

const ProcessMapView = ({ onStepClick }) => {
  return (
    <div className="relative">
      <img
        src="/images/test/process.png"
        alt="Process Map"
        useMap="#processMap"
        className="w-full h-auto"
      />
      <map name="processMap">
        {processSteps.map((step) => (
          <area
            key={step.id}
            shape="rect"
            coords={`${step.mapPosition.x},${step.mapPosition.y},${
              step.mapPosition.x + step.mapPosition.width
            },${step.mapPosition.y + step.mapPosition.height}`}
            alt={step.title}
            onClick={() => onStepClick(step)}
            className="cursor-pointer"
          />
        ))}
      </map>

      {/* Visual overlays for clickable areas */}
      {/* <div className="absolute inset-0 pointer-events-none">
        {processSteps.map((step) => (
          <div
            key={step.id}
            className={`absolute transition-colors ${
              step.status === "completed"
                ? "bg-green-500/10 hover:bg-green-500/20"
                : step.status === "current"
                ? "bg-orange-500/10 hover:bg-orange-500/20"
                : "bg-gray-500/10 hover:bg-gray-500/20"
            }`}
            style={{
              left: `${step.mapPosition.x}px`,
              top: `${step.mapPosition.y}px`,
              width: `${step.mapPosition.width}px`,
              height: `${step.mapPosition.height}px`,
            }}
          />
        ))}
      </div> */}
    </div>
  );
};

// const ProcessModal = ({ step, onClose, onStepClick }) => {
//   if (!step) return null;

//   return (
//     <div className="fixed inset-0 bg-black/50 z-50 flex items-center justify-center p-4">
//       <div
//         className={`bg-white rounded-lg ${
//           step.isProcessMap ? "max-w-3xl" : "max-w-md"
//         } w-full p-6`}
//       >
//         <div className="flex justify-between items-start mb-4">
//           <h2 className="text-xl font-semibold">{step.title}</h2>
//           <button
//             onClick={onClose}
//             className="text-gray-500 hover:text-gray-700"
//           >
//             <svg
//               className="w-6 h-6"
//               fill="none"
//               viewBox="0 0 24 24"
//               stroke="currentColor"
//             >
//               <path
//                 strokeLinecap="round"
//                 strokeLinejoin="round"
//                 strokeWidth={2}
//                 d="M6 18L18 6M6 6l12 12"
//               />
//             </svg>
//           </button>
//         </div>

//         {step.isProcessMap ? (
//           <ProcessMapView onStepClick={onStepClick} />
//         ) : (
//           <>
//             <p className="text-gray-600 mb-4">{step.details.description}</p>
//             {step.details.completedItems?.length > 0 && (
//               <>
//                 <h3 className="font-medium text-green-600 mb-2">
//                   Completed Items:
//                 </h3>
//                 <ul className="list-disc list-inside mb-4">
//                   {step.details.completedItems.map((item, index) => (
//                     <li key={index} className="text-gray-600">
//                       {item}
//                     </li>
//                   ))}
//                 </ul>
//               </>
//             )}
//             {step.details.nextSteps?.length > 0 && (
//               <>
//                 <h3 className="font-medium text-orange-500 mb-2">
//                   Next Steps:
//                 </h3>
//                 <ul className="list-disc list-inside">
//                   {step.details.nextSteps.map((item, index) => (
//                     <li key={index} className="text-gray-600">
//                       {item}
//                     </li>
//                   ))}
//                 </ul>
//               </>
//             )}
//           </>
//         )}
//       </div>
//     </div>
//   );
// };

const ProductSummaryContainer = () => {
  const dispatch = useDispatch();
  const { id } = useParams();

  const onDelete = useCallback(() => {
    dispatch({
      type: types.SHOW_MODAL,
      payload: {
        name: "ConfirmDialog",
        text: "Are you sure you want to delete this product?",
        callbacks: {
          finally: () => {},
          success: () => {},
        },
      },
    });
  }, [dispatch]);

  return <ProductSummary id={id} onDelete={onDelete} />;
};

const ProductSummary = ({ id }) => {
  const [zoomImage, setZoomImage] = useState(false);
  // const [selectedStep, setSelectedStep] = useState(null);
  const totalLeads = 5;
  const totalSales = 0;
  const totalSalesAmount = 0;
  const salesData = [
    { id: 1, customer: "الريحاني", amount: 199, status: "Pending" },
    { id: 2, customer: "آنسه فاطمه", amount: 199, status: "Pending" },
    { id: 3, customer: "عماد الدين ابراهيم", amount: 199, status: "Pending" },
    {
      id: 4,
      customer: "صلاح أبوبكر محمد عباس",
      amount: 199,
      status: "Pending",
    },
  ];

  const restockData = [
    {
      id: 1,
      trackingNumber: "On hand Amna USA",
      trackingUrl: "https://tracking-url.com/1Z999AA1234567890",
      destination: "KSA",
      quantity: 50,
      status: "In Transit",
    },
    // Add more shipments as needed
  ];

  // const handleStepClick = (step) => {
  //   setSelectedStep(step);
  // };

  return (
    <>
      {zoomImage ? (
        <ZoomImage
          src={"/images/test/mainnewturk.png"}
          setZoomImage={setZoomImage}
        />
      ) : null}
      <RollBack />
      <BreadCrumbs
        links={[
          {
            to: "/profile/products",
            name: "Products",
          },
          {
            to: `/profile/products/${id}`,
            name: "Product",
          },
        ]}
      />

      <Title title="Product Summary" />

      <div className="md:px-6 min-h-screen">
        <header className="mb-2 flex justify-between items-center">
          <h1 className="text-xl md:text-2xl font-bold text-gray-800">
            Product Details
          </h1>
          <Link
            to={`/profile/products/${id}/report`}
            className="text-blue-600 hover:underline"
          >
            View Market Report
          </Link>
        </header>

        <div className="grid grid-cols-1 md:grid-cols-3 gap-4 mb-4">
          <div className="bg-white shadow-md p-3 rounded-lg text-center">
            <img
              src="/images/test/mainnewturk.png"
              alt="Product"
              className="w-full h-32 object-contain rounded-md mb-3"
            />
            <h2 className="text-md md:text-lg font-semibold text-gray-700">
              Turkesterone & Tongkat Ali
            </h2>
          </div>
          <div className="bg-white shadow-md p-3 rounded-lg text-center">
            <h2 className="text-md md:text-lg font-semibold text-gray-700">
              Total Orders
            </h2>
            <p className="text-2xl md:text-3xl font-bold text-blue-600">
              {totalLeads}
            </p>
          </div>
          <div className="bg-white shadow-md p-3 rounded-lg text-center">
            <h2 className="text-md md:text-lg font-semibold text-gray-700">
              Total Sales
            </h2>
            <p className="text-2xl md:text-3xl font-bold text-green-600">
              {totalSales}
            </p>
            <p className="text-md text-gray-600">
              AED {totalSalesAmount.toLocaleString()}
            </p>
          </div>
        </div>

        <div className="bg-white shadow-md p-3 rounded-lg mb-4">
          <h2 className="text-md md:text-lg font-semibold text-gray-700 mb-2">
            Fulfilled By
          </h2>
          <div className="flex flex-col space-y-3">
            <div className="flex items-center gap-3">
              <img
                src="/images/test/seller_cod.svg"
                alt="ShipBob Logo"
                className="w-8 h-8 object-contain"
              />
              <h3 className="text-md md:text-lg font-semibold text-gray-800">
                COD Network
              </h3>
            </div>
            <p className="text-sm text-gray-600">
              All shipping operations and inventory management for this product
              are handled by our fulfillment partner. View real-time inventory
              levels and shipping status in the fulfillment dashboard.
            </p>
            <a
              href="https://seller.cod.network/"
              target="_blank"
              rel="noopener noreferrer"
              className="text-sm text-blue-600 hover:underline"
            >
              View Dashboard
            </a>
          </div>
        </div>

        <div className="bg-white shadow-md p-3 rounded-lg mb-4">
          <h2 className="text-md md:text-lg font-semibold text-gray-700 mb-2">
            Uploaded Assets
          </h2>
          <ul className="text-blue-600">
            <li>
              <a
                href="https://drive.google.com/drive/folders/1KqFMyu5663xxjxoigAPNlBxZ9Z8_gTpl"
                target="_blank"
                rel="noopener noreferrer"
                className="hover:underline"
              >
                Localized Creatives
              </a>
            </li>
            <li>
              <a
                href="https://turk-arabic-v1.peakrevivalx.shop/"
                target="_blank"
                rel="noopener noreferrer"
                className="hover:underline"
              >
                Localized Landing Page
              </a>
            </li>
          </ul>
        </div>

        <section className="bg-white shadow-md rounded-lg p-3 overflow-x-auto mb-8">
          <div className="flex items-center justify-between border-b pb-2 mb-3">
            <h2 className="text-lg md:text-xl font-semibold text-gray-800">
              Restock Shipments
            </h2>
            <button
              className="p-1 bg-gray-100 hover:bg-gray-200 rounded-full transition-colors"
              title="Add new shipment"
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                className="h-6 w-6 text-gray-600 hover:text-gray-800"
                fill="none"
                viewBox="0 0 24 24"
                stroke="currentColor"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth={2}
                  d="M12 4v16m8-8H4"
                />
              </svg>
            </button>
          </div>
          <div className="overflow-x-auto">
            <table className="w-full border-collapse min-w-full">
              <thead>
                <tr className="bg-gray-200 text-gray-700">
                  <th className="p-2 text-left">Tracking</th>
                  <th className="p-2 text-left">To</th>
                  <th className="p-2 text-left">Qty</th>
                </tr>
              </thead>
              <tbody>
                {restockData.map((shipment) => (
                  <tr key={shipment.id} className="border-t">
                    <td className="p-2">{shipment.trackingNumber}</td>
                    <td className="p-2">{shipment.destination}</td>
                    <td className="p-2">{shipment.quantity}</td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        </section>

        <section className="bg-white shadow-md rounded-lg p-3 overflow-x-auto mb-8">
          <h2 className="text-lg md:text-xl font-semibold text-gray-800 border-b pb-2 mb-3">
            Recent Sales
          </h2>
          <div className="overflow-x-auto">
            <table className="w-full border-collapse min-w-full">
              <thead>
                <tr className="bg-gray-200 text-gray-700">
                  <th className="p-2 text-left">Customer</th>
                  <th className="p-2 text-left">Amount</th>
                  <th className="p-2 text-left">Status</th>
                </tr>
              </thead>
              <tbody>
                {salesData.map((sale) => (
                  <tr key={sale.id} className="border-t">
                    <td className="p-2">{sale.customer}</td>
                    <td className="p-2">AED {sale.amount}</td>
                    <td
                      className={`p-2 font-semibold ${
                        sale.status === "Completed"
                          ? "text-green-600"
                          : "text-yellow-600"
                      }`}
                    >
                      {sale.status}
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        </section>

        <section className="bg-white shadow-md rounded-none mb-8 -mx-4 sm:mx-0 sm:rounded-lg overflow-hidden">
          <div className="max-w-3xl mx-auto p-4">
            <h2 className="text-xl font-semibold mb-4 text-center">
              Your Next Step
            </h2>
            {/* Add the NextStepCard */}
            <NextStepCard />
            {/* Existing ProcessMapView */}
            <ProcessMapView />
          </div>
        </section>

        {/* {selectedStep && (
          <ProcessModal
            step={selectedStep}
            onClose={() => setSelectedStep(null)}
            onStepClick={handleStepClick}
          />
        )} */}
      </div>
    </>
  );
};

const NextStepCard = () => {
  const currentStep = processSteps.find((step) => step.status === "current");

  if (!currentStep) return null;

  return (
    <div className="bg-orange-50 border border-orange-200 rounded-lg p-4 mb-6">
      <div className="flex items-start gap-4">
        <div className="p-2 bg-orange-100 rounded-lg">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            className="h-6 w-6 text-orange-600"
            fill="none"
            viewBox="0 0 24 24"
            stroke="currentColor"
          >
            <path
              strokeLinecap="round"
              strokeLinejoin="round"
              strokeWidth={2}
              d="M13 7h8m0 0v8m0-8l-8 8-4-4-6 6"
            />
          </svg>
        </div>
        <div className="flex-1">
          <h3 className="text-lg font-medium text-gray-900 mb-1">
            Next Step: {currentStep.title}
          </h3>
          <p className="text-gray-600 mb-3">{currentStep.description}</p>
          <div className="space-y-1">
            {currentStep.details.nextSteps.map((step, index) => (
              <div
                key={index}
                className="flex items-center gap-2 text-sm text-gray-600"
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  className="h-4 w-4 text-orange-500 flex-shrink-0"
                  fill="none"
                  viewBox="0 0 24 24"
                  stroke="currentColor"
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth={2}
                    d="M9 5l7 7-7 7"
                  />
                </svg>
                {step}
              </div>
            ))}
          </div>
        </div>
      </div>
    </div>
  );
};

export default React.memo(ProductSummaryContainer);
