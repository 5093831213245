import {
  ArrowRightIcon,
  CalendarIcon,
  FileTextIcon,
  ChevronRightIcon,
} from "lucide-react";
import React from "react";
export default function PaymentsCard() {
  return (
    <div className="w-full max-w-[888px] mx-auto p-6 font-sans text-sm text-[#454745]">
      <div className="flex flex-wrap gap-2">
        <button className="flex items-center px-3 py-2 text-[#163300] font-semibold bg-[#9fe870] rounded-full">
          Send
          <ArrowRightIcon className="w-4 h-4 ml-2" />
        </button>
      </div>

      <div className="mt-10">
        <div className="mb-4 flex justify-between items-end flex-wrap">
          <h3 className="text-xl font-semibold text-[#0e0f0c]">Outgoing</h3>
        </div>

        <ul className="space-y-[-1px]">
          <li>
            <a
              href="/link?urn=urn%3Awise%3Atransfers%3Ascheduled"
              className="block p-4 hover:bg-gray-50 rounded-lg"
            >
              <div className="flex items-center">
                <div className="mr-4">
                  <div className="w-12 h-12 flex items-center justify-center bg-[rgba(22,51,0,0.08)] rounded-full">
                    <CalendarIcon className="w-6 h-6" />
                  </div>
                </div>
                <div className="flex-1">
                  <h4 className="font-semibold text-[#0e0f0c]">
                    Scheduled transfers
                  </h4>
                  <p className="mt-1 text-sm">
                    Manage transfers that are due to go out.
                  </p>
                </div>
                <ChevronRightIcon className="w-5 h-5 text-[#163300]" />
              </div>
            </a>
          </li>

          <li>
            <a
              href="/link?urn=urn%3Awise%3Abatch-payments"
              className="block p-4 hover:bg-gray-50 rounded-lg"
            >
              <div className="flex items-center">
                <div className="mr-4">
                  <div className="w-12 h-12 flex items-center justify-center bg-[rgba(22,51,0,0.08)] rounded-full">
                    <FileTextIcon className="w-6 h-6" />
                  </div>
                </div>
                <div className="flex-1">
                  <h4 className="font-semibold text-[#0e0f0c]">
                    Batch payments
                  </h4>
                  <p className="mt-1 text-sm">
                    Create and send multiple transfers with one payment file.
                  </p>
                </div>
                <ChevronRightIcon className="w-5 h-5 text-[#163300]" />
              </div>
            </a>
          </li>
        </ul>
      </div>
    </div>
  );
}
