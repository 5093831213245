import { CreditCardIcon, FilterIcon } from "lucide-react";
import React from "react";
import WiseVirtualCard from "./WiseVirtualCard";
export default function WiseVirtualCardList() {
  return (
    <div className="w-full max-w-[888px] mx-auto flex flex-col gap-4 text-[#454745] text-sm leading-[21.7px] font-sans">
      <div className="max-w-[888px]">
        <div className="flex items-center gap-3 mb-4">
          <h1 className="text-2xl font-medium">Cards</h1>

          <a
            href="/issue-card"
            className="inline-flex items-center px-4 py-1 text-sm rounded-full bg-gray-100 hover:bg-gray-200 text-gray-700"
          >
            <CreditCardIcon className="w-4 h-4 mr-2" />
            Create Card
          </a>
        </div>

        <div className="border-t border-gray-100">
          <div className="flex gap-6">
            <div className="border-b-2 border-blue-600 text-gray-700 py-2.5 px-1">
              Manage
            </div>
            <div className="text-gray-500 py-2.5 px-1 cursor-pointer">
              Subscriptions
            </div>
          </div>
        </div>

        <div className="flex items-end gap-4 py-6">
          <button className="inline-flex items-center px-4 py-1.5 text-sm rounded-full bg-gray-100 hover:bg-gray-200 text-gray-700">
            <FilterIcon className="w-4 h-4 mr-2" />
            Add Filter
          </button>

          <div className="w-px h-6 bg-gray-200 mx-3" />

          <span className="text-sm text-gray-500">No filters applied</span>
        </div>

        <div className="relative">
          <div>
            <WiseVirtualCard />
          </div>
        </div>
      </div>
    </div>
  );
}
