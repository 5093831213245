import React, { useState, useEffect } from "react";

const PWAPrompt = () => {
  const [showPrompt, setShowPrompt] = useState(false);
  const [deferredPrompt, setDeferredPrompt] = useState(null);

  // const isMobile = () => {
  //   const userAgent = navigator.userAgent || window.opera;
  //   return /android|webos|iphone|ipad|ipod|blackberry|iemobile|opera mini/i.test(
  //     userAgent.toLowerCase()
  //   );
  // };

  useEffect(() => {
    // Only proceed if this is a mobile browser
    // if (!isMobile()) {
    //   setShowPrompt(false);
    //   return;
    // }

    // const handler = (e) => {
    //   // Prevent Chrome 67 and earlier from automatically showing the prompt
    //   e.preventDefault();
    //   // Stash the event so it can be triggered later
    //   setDeferredPrompt(e);
    //   // Show our custom prompt
    //   setShowPrompt(true);
    // };

    // window.addEventListener("beforeinstallprompt", handler);
    setShowPrompt(true);

    // Check if app is already installed
    const isInstalled = window.matchMedia("(display-mode: standalone)").matches;
    if (isInstalled) {
      setShowPrompt(false);
    }

    // return () => window.removeEventListener("beforeinstallprompt", handler);
  }, []);

  const handleInstallClick = async () => {
    if (!deferredPrompt) {
      // If no deferredPrompt, try to show the native prompt
      if (window.navigator && window.navigator.standalone === undefined) {
        // Not on iOS
        alert(
          'To install the app, tap the browser menu button and select "Add to Home Screen"'
        );
      } else {
        // On iOS
        alert(
          'To install the app, tap the share button and select "Add to Home Screen"'
        );
      }
      return;
    }

    try {
      // Show the install prompt
      await deferredPrompt.prompt();
      // Wait for the user to respond to the prompt
      const { outcome } = await deferredPrompt.userChoice;
      console.log(`User response to the install prompt: ${outcome}`);
      if (outcome === "accepted") {
        console.log("User accepted the install prompt");
      } else {
        console.log("User dismissed the install prompt");
      }
    } catch (error) {
      console.error("Error during installation:", error);
      alert(
        "There was an error installing the app. Please try adding it to your home screen manually."
      );
    } finally {
      // Clear the deferredPrompt
      setDeferredPrompt(null);
      setShowPrompt(false);
    }
  };

  // Don't render anything if not mobile or shouldn't show prompt
  if (!showPrompt) return null;

  return (
    <div className="fixed bottom-0 left-0 right-0 p-4 bg-white shadow-lg border-t z-50">
      <div className="max-w-md mx-auto flex items-center justify-between">
        <div className="flex-1 mr-4">
          <h3 className="text-lg font-semibold text-gray-900">Install App</h3>
          <p className="text-sm text-gray-600">
            Install our app for the best mobile experience
          </p>
        </div>
        <div className="flex gap-2">
          <button
            onClick={() => setShowPrompt(false)}
            className="px-4 py-2 text-sm text-gray-600 hover:bg-gray-100 rounded-lg"
          >
            Not now
          </button>
          <button
            onClick={handleInstallClick}
            className="px-4 py-2 text-sm text-white bg-blue-600 hover:bg-blue-700 rounded-lg"
          >
            Install
          </button>
        </div>
      </div>
    </div>
  );
};

export default PWAPrompt;
